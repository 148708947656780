export const formatWeight = (weight: string | number, weightUnit: string) => {
  switch (weightUnit) {
    case 'GRAMS':
      return `${weight}g`
    case 'KILOGRAMS':
      return `${weight}kg`
    case 'OUNCES':
      return `${weight}oz`
    case 'POUNDS':
      return `${weight}lb`
    default:
      return ''
  }
}
