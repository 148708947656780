import { ReactNode } from 'react';
import { Box, SystemProps, css } from '@storyofams/react-ui';

import styled from 'styled-components';

const Wrapper = styled(Box)`
  position: relative;

  &::before {
    content: '';
    display: block;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

interface Props extends SystemProps {
  children?: ReactNode;
  ratio?: number[][];
}

export const FixedRatio = ({ children, ratio = [[1, 1]], ...props }: Props) => {
  return (
    <Wrapper
      {...props}
      // css trick to make card's height dynamically change when parent width changes
      css={css({
        '&::before': {
          paddingTop: ratio?.map(
            (item) => `${((item[1] / item[0]) * 100).toFixed()}%`,
          ),
        },
      })}
    >
      {children ? <Content>{children}</Content> : <Content />}
    </Wrapper>
  );
};
