import { forwardRef } from 'react';
import {
  Select as RUISelect,
  InputWrapperProps,
  css,
} from '@storyofams/react-ui';

import { Props } from 'react-select';

export type SelectOptions = {
  label: string;
  value: string;
}[];

type SelectProps = {
  id?: string;
} & InputWrapperProps &
  Props;

export const Select = forwardRef((props: SelectProps, ref) => {
  return (
    <RUISelect
      ref={ref}
      width="100%"
      {...props}
      value={
        typeof props.value === 'string'
          ? // @ts-ignore
            props?.options?.find(({ value }) => value === (props.value as any))
          : props.value
      }
      // @ts-ignore
      defaultValue={props?.options?.find(
        ({ value }) => value === (props.defaultValue as any),
      )}
      css={props.customStyles 
        ? css(props.customStyles)
        : css({
        '.react-select': {
          '&__control': {
            borderRadius: 'md',
            border: '1px',
            borderColor: 'black',
            fontSize: 2,
            height: 48,
            '&:hover': {
              cursor: 'pointer',
              bg: 'grey300',
              borderColor: 'black',
            },
            '&--menu-is-open': {
              bg: 'grey300',
            },
          },
          '&__menu': {
            borderColor: 'transparent',
            zIndex: 2,
            '.react-select__option': {
              px: 2,
              py: 1,
              mb: 0.5,
              '&:hover': {
                cursor: 'pointer',
              },
              '&:hover, &:focus, &--is-selected': {
                bg: 'secondary',
              },
              '&--is-disabled': {
                color: 'grey400',
                cursor: 'not-allowed',
              },
            },
          },
        },
      })}
    />
  );
});
